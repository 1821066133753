
  span.carousel-control-next-icon, 
  span.carousel-control-prev-icon {
    display: none;
  }


  .mobile-hero-picture {
    display: none;
  }

  /*/////////////////// MEDIA QUERIES START /////////////////////////////////*/
  
  @media screen and (max-width: 700px) and (-webkit-min-device-pixel-ratio: 1) {
    .mobile-hero-picture {
      display: block;
    }

    .desktop-hero-video, 
    .desktop-hero-picture {
      display: none;
    }

  }
  
.navbar-css {  
    margin-bottom: -11%;
    font-family: 'Dancing Script', cursive;
    font-size: 140%;
    font-weight: thin;
  }
    
  .navbar-border-shadow {
      /* box-shadow: 0 5px 5px -2px rgba(0,0,0,.2) !important;     */
  }
  
  .navbar-logo {
      max-width: 34%;
    }
  
    .nav-link a {
      color: white;
      text-shadow: .3px .5px black;
      font-size: 150%;
      font-weight: bold;
    }
  
    .hovered-link a:hover {
      text-decoration: none;
      background: rgba(186, 177, 177, 0.25);
      border-radius: 5px;
      color: white !important;
      transition: .3s;
    }
    
    button.navbar-toggler {
      background: rgba(4, 1, 130, .15) !important;
  }
  
  .navbar-toggler-icon {
    background: #040182;
  }

  .nav-link {
    margin-left: 0 !important;
    display: none;
  }
  
  
  /*/////////////////// MEDIA QUERIES START /////////////////////////////////*/
  @media screen and (max-width: 500px) and (-webkit-min-device-pixel-ratio: 1) {
  
    .navbar-logo {
      max-width: 20% !important;
      position: absolute;
      margin-top: -3%
    }
    .navbar-item {
      margin-top: -4%     
    }
  }
  
  
  
  /*Galaxy 5s*/
   @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
  
    
    .navbar-css {
      /* background: linear-gradient( #040182, #7573bf) !important; */
      margin-top: -7%;
    }

    .makeTurquoise {
      background: linear-gradient(rgba(0, 200, 255, .5), rgba(0, 200, 255, .3));
    }


    #keepTransparency {
      background-color: inherit;
    }
    
  
  }
  
  /*Samsung Galaxy S9 Media Queries (In terms of Mobile only)*/
  
  @media only screen and (min-width: 360px) and (max-width: 767px) {
  
    .navbar-css {
      /* background: linear-gradient( #040182, #7573bf) !important; */
      margin-top: -7%;
    }

    .makeTurquoise {
      background: linear-gradient(rgba(0, 200, 255, .5), rgba(0, 200, 255, .3));
    }

    #keepTransparency {
      background-color: inherit;
    }
  
  }
  
  /*iPhone XR*/
  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  
  .navbar-css {
      /* background: linear-gradient( #040182, #7573bf) !important; */
      margin-top: -7%;
    }

    .makeTurquoise {
      background: linear-gradient(rgba(0, 200, 255, .5), rgba(0, 200, 255, .3));
    }

    #keepTransparency {
      background-color: inherit;
    }
  
  
  }
  
  
  /*iPhone 6/7/8*/
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  
    .navbar-css {
      /* background: linear-gradient( #040182, #7573bf) !important; */
      margin-top: -7%;
    }

    .makeTurquoise {
      background: linear-gradient(rgba(0, 200, 255, .5), rgba(0, 200, 255, .3));
    }

    #keepTransparency {
      background-color: inherit;
    }
  
    .navbar-toggler {
      position: relative;
    }
  
  }
  
  /*iPhone 6+/7+/8+ */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  
    .navbar-css {
      /* background: linear-gradient( #040182, #7573bf) !important; */
      margin-top: -7%;
    }

    .makeTurquoise {
      background: linear-gradient(rgba(0, 200, 255, .5), rgba(0, 200, 255, .3));
    }


    #keepTransparency {
      background-color: inherit;
    }
  
    .navbar-toggler {
      position: relative;
    }
  
  
  }
  
  /*iPhone X*/
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  
    .navbar-css {
      /* background: linear-gradient( #040182, #7573bf) !important; */
      margin-top: -7%;
    }

    .makeTurquoise {
      background: linear-gradient(rgba(0, 200, 255, .5), rgba(0, 200, 255, .3));
    }


    #keepTransparency {
      background-color: inherit;
    }

    .navbar-toggler {
      position: relative;
    }
  
  }
  
  /*iPhone XS*/
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  
    .navbar-css {
      /* background: linear-gradient( #040182, #7573bf) !important; */
      margin-top: -7%;
    }

    .makeTurquoise {
      background: linear-gradient(rgba(0, 200, 255, .5), rgba(0, 200, 255, .3));
    }

    #keepTransparency {
      background-color: inherit;
    }
    .navbar-toggler {
      position: relative;
    }
  }
  
  /* iphone XS Max */
  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  
    .navbar-css {
      /* background: linear-gradient( #040182, #7573bf) !important; */
      margin-top: -7%;
    }

    .makeTurquoise {
      background: linear-gradient(rgba(0, 200, 255, .5), rgba(0, 200, 255, .3));
    }

    #keepTransparency {
      background-color: inherit;
    }
  
    .navbar-toggler {
      position: relative;
    }
  
  }
  
  /*Galaxy 5s*/
  @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
    .navbar-css {
      /* background: linear-gradient( #040182, #7573bf) !important; */
      margin-top: -7%;
    }

    .makeTurquoise {
      background: linear-gradient(rgba(0, 200, 255, .5), rgba(0, 200, 255, .3));
    }

    #keepTransparency {
      background-color: inherit;
    }
  
    .navbar-toggler {
      position: relative;
    }
  }
  
  /*/////////Other androids///////////*/
  
  /*Samsung Galaxy S9 Media Queries (In terms of Mobile only)*/
  
  @media only screen and (min-width: 360px) and (max-width: 767px) {
    .navbar-css {
      /* background: linear-gradient( #040182, #7573bf) !important; */
      margin-top: -7%;
    }

    .makeTurquoise {
      background: linear-gradient(rgba(0, 200, 255, .5), rgba(0, 200, 255, .3));
    }

    #keepTransparency {
      background-color: inherit;
    }
  
    .navbar-toggler {
      position: relative;
    }
  }
  
  /*---LAPTOPS AND ABOVE---*/
  
  /* ----------- Non-Retina Screens ----------- */
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    
  }
  
  /* ----------- Retina Screens ----------- */
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }
  
  /* ----------- Wide Screens ----------- */
  
  @media only screen and (min-width: 1224px) {
  }
  
  @media only screen and (min-width: 1824px) {
  }
  
  @media (min-width: 2000) {
  }
  

  /*iPhone 5*/
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) 
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) {
    
      .navbar-css {
        /* background: linear-gradient( #040182, #7573bf) !important; */
        margin-top: -7%;
      }

      .makeTurquoise {
        background: linear-gradient(rgba(0, 200, 255, .5), rgba(0, 200, 255, .3));
      }


      #keepTransparency {
        background-color: inherit;
      }
  }

